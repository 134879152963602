<template>
    <v-hover v-if="elevated" :disabled="disabled" v-slot="{ hover }">
      <v-card :disabled="disabled" :elevation="hover && hasClickListener ? 4 : 2" :color="color" :class="{ 'pa-4 grey lighten-4': true, 'lighten-2': hover && hasClickListener }" @click="hasClickListener ? $emit('click') : null" :to="to" :href="href">
        <v-icon v-if="icon" size="50" :color="iconColor || 'grey'" class="mb-4 d-inline-block">{{icon}}</v-icon>
        <h3 :class="{ 'mb-4': !!text}">{{title}}</h3>
        <p class="mb-0">{{text}}</p>
      </v-card>
    </v-hover>
    <v-list-item v-else link three-line :disabled="disabled" @click="hasClickListener ? $emit('click') : null" :to="to" :href="href" >
        <v-list-item-icon v-if="icon"><v-icon :color="iconColor || 'grey'" size="50" class="mt-1" style="min-width:60px">{{icon}}</v-icon></v-list-item-icon>
        <v-list-item-content>
        <v-list-item-title>{{title}}</v-list-item-title>
        <v-list-item-subtitle>{{text}}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action v-if="$slots.action">
          <slot name="action"></slot>
        </v-list-item-action>
    </v-list-item>
  <!--<v-sheet :elevation="1" class="text-center align-center pa-4" @click="$emit('click')">
    <v-icon v-if="icon" size="50" color="grey">{{icon}}</v-icon>
    <h3 class="my-4">{{title}}</h3>
    <p>{{text}}</p>
  </v-sheet>-->
</template>
 
<script>

export default {
  name: "BigButton",
  components: {
  },
  props: {
    icon: String,
    iconColor: String,
    title: String,
    text: String,
    elevated: Boolean,
    disabled: Boolean,
    color: String,
    href: String,
    to: [Object, String],
  },
  data: function() {
    return {
    };
  },
  mounted() {
  },
  methods: {
      
  },
  computed: {
    hasClickListener(){
      return this.$listeners && this.$listeners.click;
    }
  }
};
</script>

<style lang="scss">

</style>

        